import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const NdaForm = ({ title, link }) => {
  return (
    <section>
      <Container
        fluid
        className="nda-section d-flex justify-content-center align-items-center"
      >
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center min-vh-100">
            <Col md={8}>
              <div className="embed-responsive embed-responsive-16by9">
                {title && (
                  <h1
                    className="section-title text-center"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h1>
                )}
                <div
                  className="section-title text-center NDA-form"
                  dangerouslySetInnerHTML={{ __html: link }}
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
};

export default NdaForm;
