import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import logo from "./Assets/Logo_GNTV.png";
import { NavLink, useNavigate } from "react-router-dom";
import OffcanvasComponent from "./OffcanvasComponent";
import "bootstrap/dist/js/bootstrap.bundle.min";

function NavBar() {
  const navigate = useNavigate();
  const [navColour, updateNavbar] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    // Fetch the menu items from the API
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    // fetch("http://127.0.0.1:8000/api/menu-data?menu_type=navbar")
    fetch(`${baseUrl}/api/menu-data?menu_type=navbar`)
      // fetch("https://admin.globalnation.tv/api/menu-data?menu_type=navbar")
      .then((response) => response.json())
      .then((data) => {
        setMenuItems(data.navigationItems);
      })
      .catch((error) => {
        console.error("Error fetching menu items:", error);
      });
  }, []);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }
  function redirect(item, target) {
    if (target) {
      window.open(item.url, target);
    } else {
      navigate(item.url);
    }
  }

  window.addEventListener("scroll", scrollHandler);
  const renderMenuItems = (items) => {
    return items.map((item) => (
      <Nav.Item
        key={item.id}
        className={item.children?.length > 0 ? "nav-item dropdown" : "nav-item"}
      >
        {item.children?.length > 0 ? (
          <Dropdown as={ButtonGroup} drop="down">
            {item.url_type !== "button" && (
              <Nav.Link as={NavLink} to={item.url} exact={item.url === "/"}>
                {item.name}
              </Nav.Link>
            )}

            <Dropdown.Toggle
              split
              variant="success"
              id="dropdown-split-basic"
              className={item.url_type === "button" && "split-button"}
            >
              {item.url_type === "button" && <span> {item.name} &nbsp;</span>}{" "}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <ul>{renderMenuItems(item.children)}</ul>
            </Dropdown.Menu>
          </Dropdown>
        ) : item.url_type === "internal" ? (
          <Nav.Link as={NavLink} to={item.url} exact={item.url === "/"}>
            {item.name}
          </Nav.Link>
        ) : (
          <div className="external-btn">
            <Button variant="success" onClick={() => redirect(item, "_blank")}>
              {item.name}
            </Button>
          </div>
        )}
        {/* <Nav.Link
          as={NavLink}
          to={item.url}
          exact={item.url === "/"}
          className={
            item.children?.length > 0 ? "nav-link dropdown-toggle" : "nav-link"
          }
          id={item.children?.length > 0 ? `dropdown-${item.id}` : undefined}
          role={item.children?.length > 0 ? "button" : undefined}
          data-bs-toggle={item.children?.length > 0 ? "dropdown" : undefined}
          aria-expanded={item.children?.length > 0 ? "false" : undefined}
        >
          {item.name}
        </Nav.Link> */}
        {/* {item.children?.length > 0 && (
          <ul className="dropdown-menu" aria-labelledby={`dropdown-${item.id}`}>
            {renderMenuItems(item.children)}
          </ul>
        )} */}
      </Nav.Item>
    ));
  };

  return (
    <>
      <Navbar fixed="top" expand="md" className={navColour ? "" : "navbar"}>
        <Container fluid>
          <Navbar.Brand
            as={NavLink}
            to="/"
            className="d-flex justify-content-center"
          >
            <img src={logo} className="img-fluid logo" alt="brand" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" defaultActiveKey="#home">
              {renderMenuItems(menuItems)}
              {/* <Nav.Item>
                <Nav.Link
                  href="https://wiki.globalnation.tv/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Knowledge BaseX
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <OffcanvasComponent
        items={menuItems}
        show={showOffcanvas}
        onHide={() => setShowOffcanvas(false)}
      />
    </>
  );
}

export default NavBar;
