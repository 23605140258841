import React from "react";
import GntvIntroEvent from "../GntvIntroEvent";
import Footer from "../Footer";
// import Navbar from "../Navbar";

const Demo = () => {
  return (
    <>
      {/* <Navbar/> */}

      <GntvIntroEvent />

      {/* <Footer /> */}
    </>
  );
};

export default Demo;
