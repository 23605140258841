import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DownloadIcon from "./Assets/Svg/DownloadIocn";
import EmailIcon from "./Assets/Svg/EmailIcon";
import FrameView from "./components/iFramePageView";
const DynamicPageContent = () => {
  const [pageData, setPageData] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(
    process.env.PUBLIC_URL +
      "/static/media/GNTV_BG_Landing-Page.651eb1397180fd19a27d.svg"
  );
  const location = useLocation();
  const { pathname } = location;
  const [pageTemplate, setPageTemplate] = useState(1);
  const [artistList, setArtistList] = useState([]);
  const [apiData, setApiData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathUrl = pathname.split("/")[1];
        let pageStatus = 1;
        if (pathname.includes("preview")) {
          pageStatus = 2;
        }
        setPageData(null);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        // const response = await fetch(`http://127.0.0.1:8000/api/all-pages-data?pageslug=${pathUrl}&pagestatus=${pageStatus}`);
        const response = await fetch(
          `${baseUrl}/api/all-pages-data?pageslug=${pathUrl}&pagestatus=${pageStatus}`
        );
        
        const data = await response.json();
        setPageTemplate(data.page_temp);
        if (data.page_temp === 2) {
          setApiData(data.download);
          if (data.download?.background_image !== "") {
            setBackgroundImage(data.download?.background_image);
          }
        } else if (data.page_temp === 3) {
          setArtistList(data.artists);
          if (data?.artists[0].background_image !== "") {
            setBackgroundImage(data.artists[0].background_image);
          }
        } else {
          if (data?.pages.background_image !== "") {
            setBackgroundImage(data?.pages.background_image);
          }

          setPageData(data.pages);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [pathname]);

  function isHtml(str) {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  }

  return (
    <section>
      {pageData?.form_link && (
        <FrameView title={pageData.page_title} link={pageData.form_link} />
      )}
      {pageTemplate === 1 && !pageData?.form_link && (
        <Container
          fluid
          className="privacypolicy-section landing-page-bg d-flex align-items-center"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <Row className="justify-content-center align-items-center">
            {pageData && (
              <>
                <h1
                  className="section-title text-center"
                  dangerouslySetInnerHTML={{ __html: pageData.page_title }}
                ></h1>
                <Col xs={12} md={10}>
                  <div
                    dangerouslySetInnerHTML={{ __html: pageData.description }}
                  ></div>
                </Col>
              </>
            )}
          </Row>
        </Container>
      )}

      {pageTemplate === 2 && (
        <Container
          fluid
          className="download-section landing-page-bg d-flex flex-column"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <Row className="justify-content-center align-items-center">
            <Col className="aspect-ratio aspect-ratio-1x1">
              <h1 className="section-title">
                {apiData.page_title && (
                  <p dangerouslySetInnerHTML={{ __html: apiData.page_title }} />
                )}
              </h1>

              <div className="download-content-sec">
                {apiData.plateform_name && (
                  <div className="main-row">
                    {apiData.plateform_name.map((platform, index) => (
                      <div className="download-row" key={index}>
                        <div className="download-title" key={index}>
                          {platform}
                        </div>
                        <div className="download-button">
                          {apiData.plateform_file[index] && (
                            <a
                              href={
                                "https://admin.globalnation.tv/_uploads/builds/" +
                                apiData.plateform_file[index]
                              }
                              className="download-btn"
                              rel="noopener noreferrer"
                            >
                              <DownloadIcon /> Download
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="download-row">
                  <div className="download-title">Android***</div>
                  <div className="download-button">
                    <a
                      className="download-btn"
                      href="mailto:beta@globalnation.tv?subject=Please%20add%20me%20to%20your%20Android%20Beta%20list!"
                    >
                      <EmailIcon />
                      Email Us
                    </a>
                  </div>
                </div>

                <div className="download-row last-row">
                  <div className="download-title">iOS/iPadOS***</div>
                  <div className="download-button">
                    <a
                      className="download-btn"
                      href="mailto:beta@globalnation.tv?subject=Please%20add%20me%20to%20your%20iOS%20Beta%20list!"
                    >
                      <EmailIcon />
                      Email Us
                    </a>
                  </div>
                </div>

                <div className="download-main-para text-white">
                  {apiData.disclaimers && (
                    <p
                      dangerouslySetInnerHTML={{ __html: apiData.disclaimers }}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      {pageTemplate === 3 && (
        <Container
          fluid
          className="featured-artist-main landing-page-bg d-flex align-items-center"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <Row className="align-items-center">
            <h1 className="section-title">Featured Artist</h1>
            {artistList.map((artist, index) => (
              <Col
                xs={12}
                md={6}
                lg={4}
                className="aspect-ratio aspect-ratio-1x1 mb-4"
                key={index}
              >
                <div className="embed-responsive embed-responsive-16by9 featured-artist-column">
                  <div className="iframe-wrapper">
                    <iframe
                      className="elementor-video-iframe"
                      allowFullScreen
                      title="vimeo Video Player"
                      src={`${artist.video_url}?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t=`}
                    ></iframe>
                  </div>
                  <div className="title">{artist.title}</div>
                  {isHtml(artist.description) ? (
                    <div
                      className="artist-description"
                      dangerouslySetInnerHTML={{ __html: artist.description }}
                    />
                  ) : (
                    <div className="artist-description">
                      {artist.description}
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      )}
      {pageTemplate === 4 && (
        <Container fluid className="d-flex justify-content-center align-items-center min-vh-100" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Row className="w-100 d-flex justify-content-center align-items-center">
          <Col md={8} className="d-flex justify-content-center align-items-center">
            <iframe
              title="Iframe Tester Application Form"
              width="100%"
              height="100%"
              id="contactform123"
              src="https://globalnation.tv/_upload/GNTV_Logo_01.03/index.htm"
              style={{ border: 'none', overflow: 'hidden' }}
              // scrolling="no"
            >
              <p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax, etc).</p>
            </iframe>
            {/* <iframe src="http://site.com/document.html" height="400" width="300" frameborder="0" >
            <p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax, etc).</p>
            </iframe> */}
          </Col>
        </Row>
      </Container>
      )}
    </section>
  );
};

export default DynamicPageContent;
