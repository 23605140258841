import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import axios from 'axios';

const CodePage = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [data, setData] = useState(null); // State to store API data
    // const [loading, setLoading] = useState(true); // State to handle loading state
    // const [error, setError] = useState(null); // State to handle errors

    useEffect(() => {
        const fetchData = async () => {
            try {
                const baseUrl = process.env.REACT_APP_API_BASE_URL;
                const response = await axios.get(`${baseUrl}/api/codepage-data/${id}`);
                setData(response.data.CodePageData[0]); // Set the data from the API response
                // setLoading(false);
            } catch (error) {
                // setError(error);
                // setLoading(false);
            }
        };

        fetchData();
        // console.log(data,'data');
        
    }, [id]); // Dependency array ensures useEffect runs when ID changes

    // if (loading) return <p>Loading...</p>; // Show loading state
    // if (error) return <p>Error: {error.message}</p>; // Show error message if there's an error

    return (
        <section>
            <Navbar />
            <Container fluid className="home-section" id="home">
                <Container fluid className="home-content">
                    <Row>
                        <Col md={7} className="home-header">
                            <h2 className="title">
                                {data?.title}
                            </h2>
                            <p>{data?.code && <div dangerouslySetInnerHTML={{ __html: data?.code }} />}</p>
                            {/* <p>Our community of creators is awaiting you!</p>
                            <p>GlobalNation™ is the reincarnation of Todd Rundgren's Creators Community PatroNet, which he launched in 1999 to offer artists like himself a direct revenue stream from supporters, or patrons, within the PatroNet community.</p>
                            <p>GlobalNation™ is about supporting the creators whose work inspires you, and becoming a creator and inspiring others.</p>
                            <p>Join us to Create, Connect with other creators, and Collect their work in support of them.</p>
                            <Link to="/downloads" className="join-btn">Download</Link> */}
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section>
    );
};

export default CodePage;