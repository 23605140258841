import React from "react";
// import Navbar from "../Navbar";
import Footer from "../Footer";
import InterocitorDevice from "../InterocitorDevice";

const Interocitor = () => {
  return (
    <>
      {/* <Navbar/> */}

      <InterocitorDevice />

      {/* <Footer /> */}
    </>
  );
};

export default Interocitor;
