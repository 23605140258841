import React from "react";

function ServiceCollect() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 168 160.095"
    >
      <defs>
        <path d="M22.465 9.678c1.868-1.047 4.36-.51 5.803 1.029 8.69 8.416 17.003 17.23 25.76 25.57 2 1.727 3.594-1.48 5.019-2.557 3.01-2.557 5.218-7.454 9.822-6.878 3.737-.028 6.464 3.887 6.256 7.407.01 10.02.066 20.05-.037 30.08.122 3.218-2.888 6.389-6.143 6.238a2155.92 2155.92 0 01-31.251 0c-4.048.538-7.36-3.916-6.803-7.643 2.151-4.737 6.982-7.407 10.238-11.295.575-1.387-.727-2.32-1.51-3.245-8.21-8.172-16.447-16.315-24.58-24.552-1.915-1.51-2.199-4.605-.519-6.388 2.406-2.812 4.935-5.605 7.945-7.766z"></path>
        <path d="M141.996 9.3c1.613-.395 3.34-.046 4.538 1.133 2.671 2.274 5.199 4.756 7.332 7.549 1.264 1.802.802 4.482-.915 5.85-8.153 8.228-16.362 16.39-24.571 24.551-.792.925-2.104 1.869-1.519 3.246 2.944 3.34 6.718 5.916 9.36 9.53 2.312 2.991.01 7.389-2.953 8.936-4.02 1.311-8.313 1.123-12.474 1.085-7.124-.226-14.295.453-21.381-.34-3.36-.15-6.624-3.029-6.51-6.51-.076-10.304-.105-20.617.008-30.911.067-4.633 5.115-8.087 9.436-6.171 3.454 2.613 5.954 6.237 9.257 9.03 1.198 1.48 2.66-.236 3.585-1.067 7.69-7.727 15.427-15.418 23.099-23.164 1.141-1.01 2.189-2.321 3.708-2.746z"></path>
        <path d="M32.957 90.75c1.83-2.227 4.982-2.199 7.615-2.208 9.464.132 18.947-.123 28.41.122 3.539.057 6.35 3.34 6.124 6.822.057 10.087.067 20.174-.018 30.261.51 4.915-6.21 9.294-10.097 5.793-3.378-2.557-5.633-6.294-8.973-8.888-1.16-1.057-2.255.537-3.104 1.198a5485.114 5485.114 0 01-24.533 24.57c-1.368 1.85-3.68 1.708-5.718 1.397-2.953-2.199-5.416-4.992-7.964-7.624-2.038-1.916-1.472-5.294.538-7.001 8.68-8.945 17.749-17.532 26.345-26.562-2.86-3.953-7.294-6.435-9.983-10.502-1.765-2.359-.434-5.472 1.358-7.378z"></path>
        <path d="M92.893 95.486c-.236-3.49 2.585-6.737 6.114-6.831 9.464-.227 18.937.01 28.411-.104 2.613.028 5.793-.047 7.614 2.18 2.067 1.877 1.755 5.038 1.189 7.51-2.774 3.68-6.746 6.219-9.671 9.766.217 1.067.783 1.991 1.613 2.708 8.313 8.04 16.456 16.258 24.58 24.477 2.019 1.707 2.585 5.104.538 7.01-3.076 3.133-5.963 6.615-9.851 8.757-1.774-.897-3.425-2.001-4.793-3.463-7.841-7.898-15.73-15.748-23.571-23.637-.877-.66-1.915-2.264-3.113-1.207-3.982 3.028-6.332 8.001-11.021 10.115-4.01.622-8.37-2.793-8.04-7.03a2155.75 2155.75 0 010-30.25z"></path>
        <path
          id="Fill_5"
          d="M0 16.145C0 7.228 7.228 0 16.145 0h135.71C160.772 0 168 7.228 168 16.145V143.95c0 8.916-7.228 16.145-16.145 16.145H16.145C7.228 160.095 0 152.866 0 143.95V16.145z"
        ></path>
      </defs>
      <g vectornatorLayerName="ccc">
        <g vectornatorLayerName="Icons">
          <g fill="#012ec5" vectornatorLayerName="collect">
            <path
              d="M22.465 9.678c1.868-1.047 4.36-.51 5.803 1.029 8.69 8.416 17.003 17.23 25.76 25.57 2 1.727 3.594-1.48 5.019-2.557 3.01-2.557 5.218-7.454 9.822-6.878 3.737-.028 6.464 3.887 6.256 7.407.01 10.02.066 20.05-.037 30.08.122 3.218-2.888 6.389-6.143 6.238a2155.92 2155.92 0 01-31.251 0c-4.048.538-7.36-3.916-6.803-7.643 2.151-4.737 6.982-7.407 10.238-11.295.575-1.387-.727-2.32-1.51-3.245-8.21-8.172-16.447-16.315-24.58-24.552-1.915-1.51-2.199-4.605-.519-6.388 2.406-2.812 4.935-5.605 7.945-7.766z"
              vectornatorLayerName="path 1"
            ></path>
            <path
              d="M141.996 9.3c1.613-.395 3.34-.046 4.538 1.133 2.671 2.274 5.199 4.756 7.332 7.549 1.264 1.802.802 4.482-.915 5.85-8.153 8.228-16.362 16.39-24.571 24.551-.792.925-2.104 1.869-1.519 3.246 2.944 3.34 6.718 5.916 9.36 9.53 2.312 2.991.01 7.389-2.953 8.936-4.02 1.311-8.313 1.123-12.474 1.085-7.124-.226-14.295.453-21.381-.34-3.36-.15-6.624-3.029-6.51-6.51-.076-10.304-.105-20.617.008-30.911.067-4.633 5.115-8.087 9.436-6.171 3.454 2.613 5.954 6.237 9.257 9.03 1.198 1.48 2.66-.236 3.585-1.067 7.69-7.727 15.427-15.418 23.099-23.164 1.141-1.01 2.189-2.321 3.708-2.746z"
              vectornatorLayerName="path 2"
            ></path>
            <path
              d="M32.957 90.75c1.83-2.227 4.982-2.199 7.615-2.208 9.464.132 18.947-.123 28.41.122 3.539.057 6.35 3.34 6.124 6.822.057 10.087.067 20.174-.018 30.261.51 4.915-6.21 9.294-10.097 5.793-3.378-2.557-5.633-6.294-8.973-8.888-1.16-1.057-2.255.537-3.104 1.198a5485.114 5485.114 0 01-24.533 24.57c-1.368 1.85-3.68 1.708-5.718 1.397-2.953-2.199-5.416-4.992-7.964-7.624-2.038-1.916-1.472-5.294.538-7.001 8.68-8.945 17.749-17.532 26.345-26.562-2.86-3.953-7.294-6.435-9.983-10.502-1.765-2.359-.434-5.472 1.358-7.378z"
              vectornatorLayerName="path 3"
            ></path>
            <path
              d="M92.893 95.486c-.236-3.49 2.585-6.737 6.114-6.831 9.464-.227 18.937.01 28.411-.104 2.613.028 5.793-.047 7.614 2.18 2.067 1.877 1.755 5.038 1.189 7.51-2.774 3.68-6.746 6.219-9.671 9.766.217 1.067.783 1.991 1.613 2.708 8.313 8.04 16.456 16.258 24.58 24.477 2.019 1.707 2.585 5.104.538 7.01-3.076 3.133-5.963 6.615-9.851 8.757-1.774-.897-3.425-2.001-4.793-3.463-7.841-7.898-15.73-15.748-23.571-23.637-.877-.66-1.915-2.264-3.113-1.207-3.982 3.028-6.332 8.001-11.021 10.115-4.01.622-8.37-2.793-8.04-7.03a2155.75 2155.75 0 010-30.25z"
              vectornatorLayerName="path 4"
            ></path>
          </g>
        </g>
        <g vectornatorLayerName="Frame rnd 2pt">
          <mask
            id="StrokeMask_5"
            width="168"
            height="160.095"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
          >
            <path d="M0 0H168V160.095H0z"></path>
            <use fill="#fff" fillRule="evenodd" xlinkHref="#Fill_5"></use>
          </mask>
          <use
            fill="none"
            stroke="#fff"
            strokeLinecap="butt"
            strokeWidth="4"
            mask="url(#StrokeMask_5)"
            xlinkHref="#Fill_5"
          ></use>
        </g>
      </g>
    </svg>
  );
}

export default ServiceCollect;