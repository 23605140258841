// OffcanvasComponent.jsx
import React  from "react";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import logo from "./Assets/Logo_GNTV.png";
import { NavLink ,useNavigate} from "react-router-dom";

function OffcanvasComponent({items, show, onHide }) {
  const navigate = useNavigate();

  function redirect(item, target) {
    if (target) {
      window.open(item.url, target);
    } else {
      navigate(item.url);
    }
  }

  const renderMenuItems = (items) => {
    return items.map((item,i) => (
      <Accordion defaultActiveKey="0" key={i}>
      <Accordion.Item eventKey={i} key={i}>
        <div className="d-flex justify-space-between">

            { item.url_type === 'internal'? <Nav.Link as={NavLink} to={item.url}>
              {item.name}
            </Nav.Link>
            :
            
            <a href="#" className="nav-link" onClick={() => redirect(item, "_blank")}>
              {item.name}
            </a>
            }

        {item?.children?.length>0?
        <Accordion.Header></Accordion.Header>
        :''}
        </div>

        {item?.children?.length>0?
        <Accordion.Body>
          {renderMenuItems(item.children)}
        </Accordion.Body>
        :''}
      </Accordion.Item>
      
    </Accordion>
    ));
  };



  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <img src={logo} className="img-fluid logo" alt="brand" />
      </Offcanvas.Header>

      <hr />
      <Offcanvas.Body>
        <Offcanvas.Title>
          <h1>Our Menu</h1>
        </Offcanvas.Title>
        <Nav defaultActiveKey="#home">
          

         
        {renderMenuItems(items)}
        </Nav>

     
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasComponent;